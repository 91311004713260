import styled from 'styled-components';

const LottieAnimationStyle = styled.div`
	&.lottie-container {
		position: relative;
		.lottie-wrapper {
			svg {
				display: block;
				width: auto;
				height: auto;
				max-width: 100%;
				z-index: 1;
			}
		}
	}
`;
export default LottieAnimationStyle;
