import styled from 'styled-components';

const HeroSectionStyle = styled.section`
	--svgSize: 37vw;
	--mT64: var(--sp8x);
	--marginLottie: 5vw;

	margin-bottom: var(--sectionDistance);

	.heroSectionWrapper {
		height: 100vh;
		position: relative;

		.sectionTitle {
			position: absolute;
			z-index: 1;
		}

		margin-top: var(--mT60);
	}

	.lottie-container {
		opacity: 0;

		div {
			margin-top: var(--marginLottie);

			svg {
				height: var(--svgSize) !important;
			}
		}
	}

	.buttons-wrap {
		display: flex;
		justify-content: center;
		margin-top: var(--mT64);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--mT64: var(--sp5x);
		--svgSize: 38vw;
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--mT64: var(--sp5x);
		--svgSize: 100%;
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--mT64: var(--sp4x);
		--svgSize: 100%;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--mT64: var(--sp4x);
		--svgSize: 100%;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--mT64: var(--sp4x);
		--svgSize: 100%;
		--marginLottie: 0;

		.heroSectionWrapper {
			.sectionTitle {
				position: relative;
			}
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--svgSize: 100%;
		--mT64: var(--sp3x);

		.heroSectionWrapper {
			.sectionTitle {
				position: relative;
			}
		}
	}
`;
export default HeroSectionStyle;
