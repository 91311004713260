import styled from 'styled-components';

const FileSectionStyle = styled.div`
	--fileHeight: 50%; /* //! Default Values */

	.file-container {
		.image-cont,
		.video-cont {
			--proportion: var(--fileHeight);
		}
	}
`;

export default FileSectionStyle;
