'use client';
import { useRef } from 'react';

//* Style
import ProblemSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const ProblemSection = ({ title, description, description_1, description_2, description_3 }) => {
	//! Refs
	const refTitle = useRef();
	const refDescription = useRef();
	const desc1 = useRef();
	const desc2 = useRef();
	const desc3 = useRef();

	return (
		<ProblemSectionStyle>
			<ScrollActive
				lineAnim
				duration={1.4}
				refsContainer={[refTitle]}>
				<Container
					row
					isSection>
					<div className='col-2 col-dm-1 col-t-12' />

					<div className='col-8 col-dm-10 col-t-12 text-center overflow-hidden'>
						<Text
							tag={`h1`}
							ref={refTitle}
							className={`white font-zen-dots-regular translate-y-100`}>
							{title}
						</Text>
					</div>

					<div className='col-2 col-dm-1 col-t-12' />

					<div className='col-3 col-t-1 col-m-12' />

					<div className='col-6 col-t-10 col-m-12 text-center mT40'>
						<ScrollActive
							opacity
							duration={0.8}
							isMobileBottomLine={'top bottom'}
							refsContainer={[refDescription]}>
							<div
								ref={refDescription}
								className='opacity-0 overflow-hidden'>
								<Text className={`p font-golos-text-regular white display-inline `}>{description}</Text>
							</div>
						</ScrollActive>
					</div>

					<div className='col-3 col-t-1 col-m-12' />

					<div className='col-1 col-dl-12' />

					<div className={`col-10 col-dl-12 hero-btn-wrapper`}>
						<ScrollActive
							animY
							// bottomLine='-300% 50%'
							isMobileBottomLine='-300% 50%'
							refsContainer={[desc1, desc2, desc3]}>
							<Container
								row
								full
								className={'pT96'}>
								<div className={`col-4 col-t-6 col-m-12 mt-16 rotateBackground`}>
									<div
										ref={desc1}
										className={'gradient-square translate-y-50'}>
										<div className={`icon-block iconL`}>
											<Icon name={`time`} />
										</div>

										<Text className={`h3 font-golos-text-regular white display-inline `}>{description_1}</Text>
									</div>
								</div>

								<div className={`col-4 col-t-6 col-m-12 mt-16 rotateBackground`}>
									<div
										ref={desc2}
										className={'gradient-square translate-y-50'}>
										<div className={`icon-block iconL`}>
											<Icon name={`target`} />
										</div>

										<Text className={`h3 font-golos-text-regular white display-inline `}>{description_2}</Text>
									</div>
								</div>

								<div className={`col-4 col-t-12 col-m-12 mt-16 rotateBackground`}>
									<div
										ref={desc3}
										className={'gradient-square translate-y-50'}>
										<div className={`icon-block iconL`}>
											<Icon name={`money`} />
										</div>

										<Text className={`h3 font-golos-text-regular white display-inline `}>{description_3}</Text>
									</div>
								</div>
							</Container>
						</ScrollActive>
					</div>

					<div className='col-1 col-dl-12' />
				</Container>
			</ScrollActive>
		</ProblemSectionStyle>
	);
};

export default ProblemSection;
