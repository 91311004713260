import styled from 'styled-components';

const ProblemSectionStyle = styled.section`
	--padding: var(--sp12x) var(--sp5x) var(--sp8x) var(--sp5x);
	--mt-16: var(--sp2x);
	--animationBeforeSizeGradient: calc(100vw / 2.8);
	--paddingBottom: var(--sp6x);

	.mt-16 {
		margin-bottom: var(--mt-16);
	}

	.gradient-square {
		position: relative;
		border: 1px solid var(--white);
		background: transparent linear-gradient(181deg, #2e58b1 100%, #e2a4ab 100%) 62% 30% no-repeat padding-box;
		border-radius: var(--sp2x);
		display: flex;
		flex-direction: column;
		padding: var(--padding);
		height: 100%;
		overflow: hidden;
	}

	@keyframes rotate-gradient {
		from {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		to {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}

	.gradient-square::before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: var(--animationBeforeSizeGradient);
		height: var(--animationBeforeSizeGradient);
		background: transparent linear-gradient(180deg, #1d408b 0%, #fc97a2 100%) 0% 0% no-repeat padding-box;
		opacity: 0.4;
		z-index: -1;
		border-radius: var(--sp2x);
	}

	.rotateBackground {
		&:nth-child(1) {
			.gradient-square {
				&::before {
					animation: rotate-gradient linear 4.7s infinite;
				}
			}
		}

		&:nth-child(2) {
			.gradient-square {
				&::before {
					animation: rotate-gradient linear 4.7s 1.6s infinite;
				}
			}
		}

		&:nth-child(3) {
			.gradient-square {
				&::before {
					animation: rotate-gradient linear 4.7s 3.2s infinite;
				}
			}
		}
	}

	.gradient-square:nth-child(2)::before {
		animation-delay: 10s;
	}

	.icon-block {
		padding-bottom: var(--paddingBottom);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--padding: var(--sp10x) var(--sp5x) var(--sp5x);
		--paddingBottom: var(--sp6x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--padding: var(--sp9x) var(--sp4x) var(--sp5x);
		--paddingBottom: var(--sp5x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--padding: var(--sp5x) var(--sp4x);
		--paddingBottom: var(--sp5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--padding: var(--sp5x) var(--sp4x);
		--paddingBottom: var(--sp5x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--padding: var(--sp5x) var(--sp4x);
		--animationBeforeSizeGradient: 100vw;
		--paddingBottom: var(--sp2x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padding: var(--sp5x) var(--sp4x);
		--animationBeforeSizeGradient: 150vw;
		--paddingBottom: var(--sp2x);
	}
`;
export default ProblemSectionStyle;
