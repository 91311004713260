import styled from 'styled-components';

const WhyWorkWithUsSectionStyle = styled.section`
	/* --height: var(--sp12x); */
	--tbPadWork: var(--sp5x);
	--lrPadWork: var(--sp4x);

	.work-item {
		width: 50%;
		padding: var(--tbPadWork) var(--lrPadWork);
		/* height: var(--height); */
		border: 1px solid var(--white);
		border-top: none;
		display: flex;
		justify-content: center;
		/* align-items: center; */
		p {
			text-align: left;
		}
	}

	.work-item:nth-child(odd) {
		border-right: none;
		justify-content: left;
		/* padding-left: var(--sp2x); */
	}

	.work-item-header {
		width: 50%;
		background-color: var(--white);
		border-radius: 16px 16px 0px 0px;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		/* --height: var(--sp10x); */
		--tbPadWork: var(--sp3x);
		--lrPadWork: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		/* --height: var(--sp10x); */
		--tbPadWork: var(--sp3x);
		--lrPadWork: var(--sp3x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		/* --height: var(--sp9x); */
		--tbPadWork: var(--sp3x);
		--lrPadWork: var(--sp3x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		/* --height: var(--sp9x); */
		--tbPadWork: var(--sp3x);
		--lrPadWork: var(--sp3x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		/* --height: var(--sp7x); */
		--tbPadWork: var(--sp2-5x);
		--lrPadWork: var(--sp3x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		/* --height: var(--sp7x); */
		--tbPadWork: var(--sp2x);
		--lrPadWork: var(--sp2x);

		.work-item-header:nth-child(1) {
			display: none;
		}

		.work-item:nth-child(odd) {
			width: 80%;
		}

		.work-item:nth-child(even) {
			width: 20%;
		}

		.work-item-header:nth-child(2) {
			width: 100%;
		}
	}
`;
export default WhyWorkWithUsSectionStyle;
