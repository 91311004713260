import { forwardRef, useRef, useImperativeHandle, useState } from 'react';

//* Style
import FileSectionStyle from './style';

//* Components
import Image from '../Image';
import Video from '../Video';
import Icon from '@/components/global/Icon';

const FileSection = forwardRef(({ file, objectFit = 'cover', className, priority = true }, ref) => {
	//! Ref
	const fileRef = useRef();

	//! States
	const [isPlaying, setIsPlaying] = useState(false);
	const [showControls, setShowControls] = useState(true);

	//! Imperative Handle
	useImperativeHandle(ref, () => fileRef.current, []);

	//! Toggle play
	const togglePlay = () => {
		const file = fileRef.current;

		const video = file.querySelector('video');

		if (!video) return;

		if (video.paused || video.ended) {
			video.play();
			setIsPlaying(true);
			setShowControls(false);
		} else {
			video.pause();
			setIsPlaying(false);
			setShowControls(true);
		}
	};

	const isVideo = file.type.startsWith('video');

	const handleMouseEnter = () => {
		setShowControls(true);
	};

	const handleMouseLeave = () => {
		isPlaying && setShowControls(false);
	};

	const handleEnd = () => {
		setShowControls(true);
		setIsPlaying(false);
	};

	return (
		<FileSectionStyle className={`file-section ${className || ''}`}>
			<div
				className='file-container'
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}>
				{file.type === 'image' ? (
					<Image
						ref={fileRef}
						src={file.src}
						alt={file.alt}
						priority={priority}
						className={objectFit}
					/>
				) : (
					<Video
						loop={false}
						ref={fileRef}
						src={file.src}
						autoPlay={false}
						onEnded={handleEnd}
					/>
				)}

				{isVideo && showControls && (
					<Icon
						onClick={togglePlay}
						className={'video-icon iconM'}
						name={isPlaying ? 'pause' : 'play'}
					/>
				)}
			</div>
		</FileSectionStyle>
	);
});

FileSection.displayName = 'FileSection';

export default FileSection;
