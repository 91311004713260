'use client';
import { useRef } from 'react';

//* Style
import WhyWorkWithUsSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const WhyWorkWithUsSection = ({ title, work_with_us }) => {
	//! Refs
	const titleRef = useRef();
	const refDescription = useRef();

	return (
		<WhyWorkWithUsSectionStyle>
			<Container
				row
				isSection>
				<div className={`col-2 col-dl-1 col-t-12`} />

				<div className={`col-8 col-dl-10 col-t-12`}>
					<Container
						row
						full
						className={'justify-center'}>
						<ScrollActive
							lineAnim
							refsContainer={[titleRef]}>
							<div className={`col-12 text-center overflow-hidden`}>
								<Text
									ref={titleRef}
									tag={`h1`}
									className={`white font-zen-dots-regular translate-y-100`}
									text={'whyUsTitle'}
								/>
							</div>
						</ScrollActive>

						<ScrollActive
							animY
							isMobileBottomLine='-160% 50%'
							refsContainer={[refDescription]}>
							<div
								className={`col-12 text-center translate-y-50`}
								ref={refDescription}>
								<div className='d-flex pT96'>
									<div className='work-item work-item-header' />

									<div className='work-item work-item-header text-center'>
										<Text className={`darkBlue h3 font-golos-text-bold`}>{title}</Text>
									</div>
								</div>

								{work_with_us.map((item, index) => (
									<div
										className={`d-flex`}
										key={index}>
										<div className={'work-item'}>
											<Text className={`white h3 font-golos-text-regular`}>{item.title}</Text>
										</div>

										<div className={'work-item'}>
											<div className={`icon-block iconM`}>
												<Icon name={`check`} />
											</div>
										</div>
									</div>
								))}
							</div>
						</ScrollActive>
					</Container>
				</div>

				<div className={`col-2 col-dl-1 col-t-12`} />
			</Container>
		</WhyWorkWithUsSectionStyle>
	);
};

export default WhyWorkWithUsSection;
