'use client';

import { useEffect, useRef, useState } from 'react';

//* Style
import AboutSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Container from '@/components/global/Container';
import FileSection from '@/components/global/FileSection';
import ScrollActive from '@/components/global/ScrollActive';

const AboutSection = ({ title, description, file }) => {
	//! Refs
	const refTitle = useRef();
	const refDescription = useRef();

	//! State
	const [isScroll, setIsScroll] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', function () {
			setIsScroll(true);
		});

		return () => {
			window.removeEventListener('scroll', function () {
				setIsScroll(true);
			});
		};
	}, [isScroll]);

	return (
		<AboutSectionStyle>
			<Container
				row
				isSection>
				<div className='col-2 col-dm-1 col-t-12' />

				<div className='col-8 col-dm-10 col-t-12 text-center mT40 overflow-hidden'>
					<ScrollActive
						duration={1.4}
						lineAnim={isScroll}
						refsContainer={[refTitle]}>
						<Text
							tag={`h1`}
							ref={refTitle}
							className={`white font-zen-dots-regular translate-y-100`}>
							{title}
						</Text>
					</ScrollActive>
				</div>

				<div className='col-2 col-dm-1 col-t-12' />

				<div className='col-3 col-t-1 col-m-12' />

				<div className='col-6 col-t-10 col-m-12 text-center mT40'>
					<ScrollActive
						duration={0.9}
						opacity={isScroll}
						refsContainer={[refDescription]}>
						<div
							ref={refDescription}
							className='opacity-0'>
							<Text className={`p font-golos-text-regular white display-inline `}>{description}</Text>
						</div>
					</ScrollActive>
				</div>

				<div className='col-3 col-t-1 col-m-12' />

				<div className='col-1 col-dl-12' />

				<div className={`col-10 col-dl-12`}>
					<FileSection
						file={file}
						className={'pT96'}
					/>
				</div>

				<div className='col-1 col-dl-12' />
			</Container>
		</AboutSectionStyle>
	);
};

export default AboutSection;
