import styled from 'styled-components';

const FAQSectionStyle = styled.section`
	--padding: var(--sp5x);
	--marginBottom: var(--sp5x);
	--sizeIconContainer: var(--sp6x);
	--sizeIcon: 12px;

	.faq-row {
		width: 100%;
		-webkit-backdrop-filter: blur(44px);
		backdrop-filter: blur(44px);
		padding: var(--padding);
		margin-bottom: var(--marginBottom);
		cursor: pointer;
	}

	.faq-item {
		width: 80%;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.everyContent {
		overflow: hidden;
		height: 0;
		opacity: 0;
	}

	.hoverPaqEveryItem {
		/* background-color: rgba(99, 78, 195, 0.2); */
		.translate-y-50 {
			transition: background-color var(--trTime) ease-out;
			background-color: rgba(99, 78, 195, 0.2);
			@media (hover: hover) {
				&:hover {
					background-color: rgba(74, 142, 204, 0.3);
					background-image: linear-gradient(to right, rgba(39, 72, 163, 1), rgba(43, 74, 162, 0.5));
					box-shadow: -10px -10px -10px -10px rgba(29, 65, 147, 0.9);
					-webkit-backdrop-filter: blur(40px);
					backdrop-filter: 40px;
				}
			}
		}
	}

	.openContent {
		opacity: 1;
		transition: opacity 0.7s ease-out;
	}

	.closeContent {
		opacity: 0;
		transition: opacity 0.7s ease-out;
	}

	.icon-block {
		width: 10%;
		display: flex;
		justify-content: left;
		padding-left: var(--sp2x);

		.iconM {
			position: relative;
			height: fit-content;
			width: var(--sizeIconContainer);
			height: var(--sizeIconContainer);
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translateY(25%);

			.all-version {
				background-color: var(--white);
				width: var(--sizeIconContainer);
				height: var(--sizeIcon);
				&.plus-version {
					position: absolute;
					left: 50%;
					top: 50%;

					transform: translate(-50%, -50%) rotate(90deg);
					transition: transform var(--trTime) ease-out;
				}
			}
			&.active {
				.all-version {
					&.plus-version {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--padding: var(--sp3x) var(--sp4x);
		--marginBottom: var(--sp3x);
		--sizeIconContainer: var(--sp4x);
		--sizeIcon: var(--sp1x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--padding: var(--sp3x) var(--sp4x);
		--marginBottom: var(--sp3x);
		--sizeIconContainer: var(--sp4x);
		--sizeIcon: var(--sp1x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--padding: var(--sp4x) var(--sp6x);
		--marginBottom: var(--sp2x);
		--sizeIconContainer: 24px;
		--sizeIcon: 6px;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--padding: var(--sp4x) var(--sp6x);
		--marginBottom: var(--sp2x);
		--sizeIconContainer: 24px;
		--sizeIcon: 6px;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--padding: var(--sp4x) var(--sp6x);
		--marginBottom: var(--sp2x);
		--sizeIconContainer: 24px;
		--sizeIcon: 6px;

		.icon-block {
			.iconM {
				transform: translateY(30%);
			}
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padding: var(--sp3x) var(--sp2x);
		--marginBottom: var(--sp3x);
		--sizeIconContainer: var(--sp2x);
		--sizeIcon: var(--sp0-5x);

		.icon-block {
			width: 15%;
			padding-left: unset;
			.iconM {
				transform: translateY(40%);
			}
		}
	}
`;
export default FAQSectionStyle;
