'use client';

//* Components
import Page from '@/components/global/Page';
import FAQSection from './components/FAQSection';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ProblemSection from './components/ProblemSection';
import ContactUsSection from './components/ContactUsSection';
import WhyWorkWithUsSection from './components/WhyWorkWithUsSection';

//* Style
import HomePageStyle from '@/styles/pages/HomePageStyle';

export default function HomeTemplate({ pageData }) {
	return (
		<Page className={'home'}>
			<HomePageStyle>
				<HeroSection {...pageData.hero} />

				<AboutSection {...pageData.about} />

				<ProblemSection {...pageData.problem} />

				<WhyWorkWithUsSection
					items={pageData.work_with_us_info}
					{...pageData.work_with_us_info}
				/>

				<FAQSection
					items={pageData.faq}
					{...pageData.faq}
				/>

				<ContactUsSection {...pageData.problem} />
			</HomePageStyle>
		</Page>
	);
}
