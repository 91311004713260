'use client';

//* Style
import ContactUsSectionStyle from './style';

//* Components
import Container from '@/components/global/Container';
import ContactUsSectionPart from '@/components/sections/ContactUsSection';

const ContactUsSection = () => {
	return (
		<ContactUsSectionStyle>
			<Container
				row
				isSection>
				<div className={`col-2 col-dl-1 col-t-12`} />

				<div className={`col-8 col-dl-10 col-t-12`}>
					<ContactUsSectionPart />
				</div>

				<div className={`col-2 col-dl-1 col-t-12`} />
			</Container>
		</ContactUsSectionStyle>
	);
};

export default ContactUsSection;
