import styled from 'styled-components';

const AboutSectionStyle = styled.section`
	--filesHeight: 56%;
	--videoFilePadTop: 55%;
	--padding: var(--sp6x);

	.file-container {
		position: relative;

		.image-cont,
		.video-cont {
			--proportion: var(--filesHeight) !important;
			padding-top: var(--videoFilePadTop);
		}
	}

	.video-icon {
		padding: var(--padding);
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		cursor: pointer;
		z-index: 100000000;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--padding: var(--sp5x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--padding: var(--sp4x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--padding: var(--sp4x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--padding: var(--sp3x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--padding: var(--sp3x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--padding: var(--sp3x);
	}
`;
export default AboutSectionStyle;
