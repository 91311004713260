'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';

//* Style
import FAQSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Editor from '@/components/global/Editor';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const FAQSection = ({ items }) => {
	//! State
	const [openIndex, setOpenIndex] = useState(null);

	//! Refs
	const refsArray = items.map(() => useRef(null));
	const wrapperFaqContent = useRef();
	const faqRef = useRef();

	const toggleDescription = (index) => {
		setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	useEffect(() => {
		const paddingTopValue = getComputedStyle(document.documentElement).getPropertyValue('--paddingTop32');

		const openContentClass = document.getElementsByClassName('openContent');
		const openWrapperEditorClass = document.getElementsByClassName('openWrapperEditor');

		//! Collapse Effect
		gsap.to('.closeContent', {
			height: 0,
			overflow: 'hidden',
			duration: 0.4,
			ease: 'power2.out',
		});

		if (openContentClass?.length > 0) {
			gsap.fromTo(
				'.openContent',
				{
					height: 0,
				},
				{
					height: 'auto',
					duration: 0.4,
					ease: 'power2.out',
				}
			);
		}

		//! pT32
		gsap.to('.closeWrapperEditor', {
			duration: 0.4,
			paddingTop: 0,

			ease: 'power2.out',
		});

		if (openWrapperEditorClass?.length > 0) {
			gsap.fromTo(
				'.openWrapperEditor',
				{
					paddingTop: 0,
				},
				{
					paddingTop: paddingTopValue,
					duration: 0.4,
					ease: 'power2.out',
				}
			);
		}
	}, [openIndex]);

	const contentData = useMemo(() => {
		return items.map((item, index) => (
			<div
				key={index}
				className='hoverPaqEveryItem'>
				<div
					ref={refsArray[index]}
					className={`d-flex faq-row translate-y-50`}
					onClick={() => toggleDescription(index)}>
					<div className={'icon-block text-center'}>
						<div className={`iconM ${openIndex === index ? 'active' : ''}`}>
							<div className={'all-version plus-version'} />
							<div className={'all-version'} />
						</div>
					</div>

					<div className={'faq-item text-left'}>
						<Text className={`white h3 font-zen-dots-regular`}>{item.title}</Text>

						<div className={`${openIndex === index ? 'openWrapperEditor' : 'closeWrapperEditor'}`}>
							<Editor
								className={`white h4 font-golos-text-regular everyContent ${openIndex === index ? 'openContent' : 'closeContent'}`}
								data={item.description}
							/>
						</div>
					</div>
				</div>
			</div>
		));
	}, [openIndex]);

	return (
		<FAQSectionStyle>
			<Container
				row
				isSection>
				<div className={`col-2 col-dl-1 col-t-12`} />
				<div className={`col-8 col-dl-10 col-t-12`}>
					<Container
						full
						row
						className={'justify-center'}>
						<ScrollActive
							lineAnim
							duration={1.4}
							refsContainer={[faqRef]}>
							<div className={`col-12 text-center overflow-hidden`}>
								<Text
									tag={`h1`}
									ref={faqRef}
									className={`white font-zen-dots-regular translate-y-100`}
									text={'faqTitle'}
								/>
							</div>
						</ScrollActive>

						<div className={`col-12`}>
							<ScrollActive
								animY
								duration={0.8}
								isMobileBottomLine={'-50% 50%'}
								refsContainer={refsArray}
								vertical={wrapperFaqContent}>
								<div
									className='pT96'
									ref={wrapperFaqContent}>
									{contentData}
								</div>
							</ScrollActive>
						</div>
					</Container>
				</div>

				<div className={`col-2 col-dl-1 col-t-12`} />
			</Container>
		</FAQSectionStyle>
	);
};

export default FAQSection;
