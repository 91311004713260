import styled from 'styled-components';

const ContactUsSectionStyle = styled.section`
	--paddingTop: var(--sp9x);
	--marginTop: var(--sp5x);

	.pT {
		padding-top: var(--paddingTop);
	}

	.mT {
		margin-top: var(--marginTop);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingTop: var(--sp7x);
		--marginTop: var(--sp4x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingTop: var(--sp7x);
		--marginTop: var(--sp4x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingTop: var(--sp7x);
		--marginTop: var(--sp4x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingTop: var(--sp7x);
		--marginTop: var(--sp3x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingTop: var(--sp5x);
		--marginTop: var(--sp3x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--paddingTop: var(--sp4x);
		--marginTop: var(--sp2x);
	}
`;
export default ContactUsSectionStyle;
