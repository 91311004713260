import styled from 'styled-components';

const VideoStyle = styled.div`
	--videoPadTop: 50%; //** Default size */

	&.video-cont {
		position: relative;
		width: 100%;
		padding-top: var(--videoPadTop);

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			z-index: 1;
		}
	}
`;

export default VideoStyle;
