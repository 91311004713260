'use client';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Lottie
import heroLottie from '@/lottie/element.json';

//* Style
import HeroSectionStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';
import PopupContactUs from '@/components/global/PopupContactUs';
import LottieAnimation from '@/components/global/LottieAnimation';

const HeroSection = ({ title, subtitle, openPopup, winWidth, translate }) => {
	//! Refs
	const refTitle = useRef();
	const refDescription = useRef();
	const refButton = useRef();
	const lottieWrapperRef = useRef();

	useEffect(() => {
		gsap.to('.heroSectionWrapper', {
			height: 'auto',
			duration: 0.8,
			ease: 'power1.out',
		});
	}, []);

	return (
		<HeroSectionStyle>
			<div className='heroSectionWrapper mt-60'>
				<Container
					row
					className={'justify-center sectionTitle'}>
					<div className='col-4 col-t-12' />

					<ScrollActive
						animY
						delay={1.1}
						duration={1.1}
						isMobileDuration={0.8}
						isMobileDelayed={0.1}
						refsContainer={[refTitle]}>
						<div className={`col-4 col-t-12 text-center overflow-hidden`}>
							<Text
								ref={refTitle}
								className={`h2 white font-zen-dots-regular translate-y-100`}>
								{title}
							</Text>
						</div>
					</ScrollActive>

					<div className='col-4 col-t-12' />

					<div className='col-2 col-dm-1 col-t-12' />

					<ScrollActive
						lineAnim
						delay={1.8}
						duration={1.1}
						isMobileDuration={0.8}
						isMobileDelayed={0.3}
						stagger={0.15}
						refsContainer={[refDescription]}>
						<div className='col-8 col-dm-10 col-t-12 text-center mT40 overflow-hidden'>
							<Text
								ref={refDescription}
								tag={`h1`}
								className={`white font-zen-dots-regular translate-y-100`}>
								{subtitle}
							</Text>
						</div>
					</ScrollActive>

					<div className='col-2 col-dm-1 col-t-12' />

					<div className='col-12'>
						<ScrollActive
							animY
							delay={2}
							duration={1.1}
							isMobileDuration={1}
							isMobileDelayed={0.5}
							refsContainer={[refButton]}>
							<div className='buttons-wrap overflow-hidden'>
								<div
									ref={refButton}
									className='translate-y-110'>
									<Button
										text={translate('requestDemo')}
										className={'primary'}
										onClick={() => openPopup(<PopupContactUs />)}
									/>
								</div>
							</div>
						</ScrollActive>
					</div>
				</Container>

				<Container row>
					<div className='empty-col col-1 col-dl-12' />

					<ScrollActive
						opacity
						duration={0.8}
						bottomLine={'top 100%'}
						refsContainer={[lottieWrapperRef]}>
						<div className='col-10 col-dl-12 sectionLottie overflow-hidden'>
							<LottieAnimation
								loop
								autoplay={false}
								animData={heroLottie}
								ref={lottieWrapperRef}
								delayPlay={winWidth < 768 ? { timeout: 1100 } : { timeout: 3100 }}
							/>
						</div>
					</ScrollActive>

					<div className='empty-col col-1 col-dl-12' />
				</Container>
			</div>
		</HeroSectionStyle>
	);
};

export default withLanguageContext(withUIContext(HeroSection, ['openPopup', 'winWidth']), ['translate']);
