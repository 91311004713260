import styled from 'styled-components';

const EditorStyle = styled.div`
	--paddingTop: var(--sp8x);

	p,
	h5,
	h6 {
		letter-spacing: 0px;
		color: var(--white);
	}

	p {
		font-size: var(--h4);
	}

	h5,
	h6 {
		font-size: var(--h3);
		font-family: var(--zen_dots);
		padding-top: var(--paddingTop);
		padding-bottom: var(--mT40);
	}

	a {
		text-decoration: underline;
		color: var(--successColor);
	}

	ul {
		padding-left: var(--sp2x);

		li {
			list-style-type: square;

			&:marker {
				width: 10px;
				height: 10px;
				color: var(--white);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingTop: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingTop: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingTop: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--paddingTop: var(--sp4x);
	}
`;

export default EditorStyle;
